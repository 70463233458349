import React, { useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react"
import GridSystem from "../../../../../../../tailwind-grid/grid"
import ColSpanSix from "../../../../../../../tailwind-grid/col-span-six"
import { useResourceStrings } from "../../../../../use-resource-strings"
import { DisplayProductCard } from "../../../../../../../display-product-card"
import { OffHireForm } from "../../../../../../../off-hire-form"
import { IBadData } from "../../../../../../../../types/interfaces"
import {
  getAvailableOffhireItemsByContractId,
  getContactsForSiteBySiteId,
} from "../../../../../../../../middleware/middleware-layer"
import { useProductData } from "../../../../../../../../graphql-static/use-commercetools-products"
import { useCustomerContext } from "../../../../store"
import { IContractDetailItem } from "../../../../../../../../types/interfaces/IContractDetail"
import { transformContactObjToOption } from "../../../../../../../../utils/transformContactObjToOption"

const ArrangeCollection = (props: any) => {
  const { customer, selectedContract, offHireItems, setOffHireItems } =
    useCustomerContext()
  const { arrangeCollectionTitle } = useResourceStrings()
  const [loadingItems, setLoadingItems] = useState(false)
  const [showBadDataMessage, setShowBadDataMessage] = useState<IBadData>({
    showMessage: false,
    message: "",
  })
  const [contractSiteId, setContractSiteId] = useState<
    null | number | undefined
  >(null)
  const [contactsForSiteBySiteId, setContactsForSiteBySiteId] = useState([])
  const { accounts, instance } = useMsal()
  const productData = useProductData()

  // Passed to the off hire form to allow geocoding to get W3W collection location
  const siteAddress = {
    addressLine1: selectedContract.siteAddLine1,
    addressLine2: selectedContract.siteAddLine2,
    addressTown: selectedContract.siteTown,
    addressCounty: selectedContract.siteCounty,
    addressPostcode: selectedContract.sitePostCode,
    addressLat: selectedContract.siteLatitude,
    addressLong: selectedContract.siteLongitude,
    addressWhat3Words: selectedContract.siteWhat3Words,
  }

  useEffect(() => {
    if (accounts.length === 0) {
      setShowBadDataMessage({
        showMessage: true,
        message: "Could not retrieve your account information",
      })
      return
    }
    if (!offHireItems.length) {
      setLoadingItems(true)
      getAvailableOffhireItemsByContractId(
        accounts,
        instance,
        Number(props.contractId)
      )
        .then(result => {
          setLoadingItems(false)
          if (result.contractId === "") {
            setShowBadDataMessage({
              showMessage: true,
              message: `We could not find any contract data with ID ${props.contractId}`,
            })
          } else {
            setContractSiteId(result?.siteId)
            setOffHireItems(result.availableForOffhire)
            setShowBadDataMessage({ showMessage: false, message: "" })
          }
        })
        .catch(err => {
          console.log(err)
          setLoadingItems(false)
          setShowBadDataMessage({
            showMessage: true,
            message: `We could not fetch contract data with ID ${props.contractId}`,
          })
        })
    } else {
      setContractSiteId(selectedContract?.siteId)
    }
  }, [])

  useEffect(() => {
    if (props.customerId && contractSiteId && customer) {
      getContactsForSiteBySiteId(
        accounts,
        instance,
        +props.customerId,
        +contractSiteId
      ).then(result => {
        if (
          result?.data?.internalGetContactsForSiteBySiteId?.contacts?.length
        ) {
          setContactsForSiteBySiteId(
            result.data.internalGetContactsForSiteBySiteId.contacts.map(
              transformContactObjToOption
            )
          )
          setShowBadDataMessage({ showMessage: false, message: "" })
        } else {
          setShowBadDataMessage({
            showMessage: true,
            message: `We could not find any contacts with Customer ID ${props.customerId} and Site ID ${customer.mainAddressId}`,
          })
        }
      })
    }
  }, [props.customerId, contractSiteId])

  const getWarningMessage = (item: any) => {
    if (item.supplierName && !item.supplierContactEmail) {
      return `${item.supplierContactName} at ${item.supplierName} does not have an email address - no off-hire confirmation email will be sent to ${item.supplierName} for this item.`
    }
  }

  return (
    <>
      <h1 className="mb-8 text-3xl font-bold uppercase">
        {arrangeCollectionTitle.toUpperCase()}
      </h1>
      <GridSystem>
        <ColSpanSix>
          {loadingItems && <p className="p-5 text-lg">Loading ...</p>}
          {offHireItems.map((item: IContractDetailItem) => {
            return (
              <DisplayProductCard
                key={item.sequenceNo}
                productName={item.equipmentDesc}
                productCode={item.sku}
                productData={productData}
                sequenceNo={item.sequenceNo}
                pathway="offhire"
                warningMessage={getWarningMessage(item)}
              />
            )
          })}

          {showBadDataMessage.showMessage && (
            <p className="w-full py-16 text-2xl font-bold uppercase">
              {showBadDataMessage.message}
            </p>
          )}
        </ColSpanSix>
        <ColSpanSix>
          <OffHireForm
            customerId={props.customerId}
            contractId={props.contractId}
            siteAddress={siteAddress}
            contactsList={contactsForSiteBySiteId}
          />
        </ColSpanSix>
      </GridSystem>
    </>
  )
}

export default ArrangeCollection
